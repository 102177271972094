import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/system';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Typography from '@mui/material/Typography';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { formatWallet } from '@opulous/web/src/shared/helpers';
import PopoverMenu from '@opulous/web/src/components/Common/PopoverMenu';
import { CircularProgress, Dialog } from '@mui/material';
import _ErrorIcon from '@mui/icons-material/Error';
import _WarningIcon from '@mui/icons-material/Warning';
import useOpulPurchasePopup from 'src/hooks/useOpulPurchasePopup';

const WalletButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: `${theme.spacing(0.75, 2)} !important`,
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(0.25, 1.5)} !important`,
    fontSize: theme.spacing(1.75),
  },
}));

const DisconnectMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '& svg': {
    fontSize: theme.spacing(2.25),
    marginRight: theme.spacing(1),
  },
}));

const AddressMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingBottom: theme.spacing(1.75),
}));

const StyledOpulPurchaseMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const ErrorIcon = styled(_ErrorIcon)(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  fill: theme.palette.error.main,
}));
const WarningIcon = styled(_WarningIcon)(({ theme }) => ({
  width: '3rem',
  height: '3rem',
  fill: theme.palette.warning.main,
}));

interface MenuProps {
  onDisconnect: () => void;
  wallet: string;
}

const OpulPurchaseMenuItem = () => {
  const { isLoading, onPurchase, isOptingIn, buttonText, isTimeout, hasError } =
    useOpulPurchasePopup();
  const [shouldShowDialog, setShouldShowDialog] = useState(true);

  const handleClick: React.MouseEventHandler = async e => {
    e.stopPropagation();
    setShouldShowDialog(true);
    await onPurchase();
  };

  if (isLoading) {
    return (
      <StyledOpulPurchaseMenuItem>
        <CircularProgress size="1.5rem" color="primary" />
      </StyledOpulPurchaseMenuItem>
    );
  }

  return (
    <>
      <StyledOpulPurchaseMenuItem onClick={handleClick}>
        {buttonText}
      </StyledOpulPurchaseMenuItem>

      <Dialog
        title="OPUL Opt-In"
        onClose={() => setShouldShowDialog(false)}
        maxWidth="lg"
        open={shouldShowDialog && isOptingIn}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          padding={4}
          alignItems="center"
        >
          {isTimeout ? (
            <>
              <WarningIcon />
              <span>Operation has timed out</span>
            </>
          ) : hasError ? (
            <>
              <ErrorIcon />
              <span>An unexpected error ocurred</span>
            </>
          ) : (
            <>
              <span>Please opt in to OPUL before purchase</span>
              <CircularProgress size="1.5rem" color="primary" />
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

const WalletDropDownMenu: React.FC<MenuProps> = ({ onDisconnect, wallet }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleWalletClick = async () => {
    await navigator.clipboard.writeText(wallet);

    enqueueSnackbar('Copied wallet address!', {
      variant: 'success',
    });
  };

  return (
    <PopoverMenu
      anchor={
        <WalletButton
          color="primary"
          variant="outlined"
          endIcon={<AccountBalanceWallet color="primary" />}
        >
          {formatWallet(wallet)}
        </WalletButton>
      }
    >
      <AddressMenuItem onClick={handleWalletClick}>
        <Typography variant="overline" gutterBottom color="textSecondary">
          Wallet address
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography>{formatWallet(wallet, 15, 3)}</Typography>
          <ContentCopy sx={{ fontSize: 16 }} />
        </Box>
      </AddressMenuItem>
      <OpulPurchaseMenuItem />
      <DisconnectMenuItem onClick={onDisconnect}>
        <PowerSettingsNewIcon />
        <Typography variant="body2">Disconnect wallet</Typography>
      </DisconnectMenuItem>
    </PopoverMenu>
  );
};

export default WalletDropDownMenu;
