import { PeraWalletConnect } from '@perawallet/connect';
import algosdk from 'algosdk';
import BigNumber from 'bignumber.js';
import MicroUSDC from './valueObjects/MicroUSDC';
import MinorOPUL from './valueObjects/MinorOPUL';

export type AlgoAsset = {
  assetId: number;
  amount: number;
};

export type WalletConnect = typeof PeraWalletConnect.prototype.connector;

export type Action<T> = {
  type: T;
  payload?:
    | string
    | { wallet: string; hasMFTs: boolean }
    | PeraWalletConnect
    | string[];
};

export interface IWalletContextState {
  isReady: boolean;
  timestamp: number;
  hasMFTs: boolean;
  wallet: string;
  walletType?: WalletTypeEnum;
  connector: PeraWalletConnect;
  error: string;
}

export enum WalletTypeEnum {
  peraWallet = 'pera',
  myAlgoWallet = 'MAW',
}

export enum AssetType {
  song = 'song',
  artwork = 'artwork',
  opul = 'opul',
  mft = 'mft',
  usdc = 'usdc',
  ovault = 'ovault',
}

export enum ContractStatus {
  pending = `pending`,
  queued = `queued`,
  issued = `issued`,
  optedin = `optedin`,
  claimed = `claimed`,
  failed = `failed`,
  assetOptedIn = `asset-optin`,
}

export interface Option {
  label: string;
  value?: string | number;
}

export interface PoolOption {
  label: string;
  value?: string | number;
  stakes?: string | number;
}

export interface IAssetField {
  label: string;
  value: string;
  identifier: string;
}

export interface ISection {
  title: string;
  items: string[] | SectionArtistItem[];
  order: number;
  type: SectionType;
  note?: string;
}

export interface SectionArtistItem {
  image: string;
  name: string;
  descriptions: string[];
}

export enum SectionType {
  highlights = 'highlights',
  artists = 'artists',
}

export interface NFTSectionContent {
  order: number;
  type: SectionType;
}

export type AssetMFT = {
  assetId: number;
  unitName: string;
};
export interface Asset {
  id: number;
  unitName: string;
  name: string;
  title: string;
  subTitle?: string;
  artist: string;
  type: AssetType;
  nftCount: number;
  thumbnail: string;
  decimals: number;
  description?: string;
  image: string;
  custom?: {
    title?: string;
    description?: string;
    artist?: string;
    fields?: IAssetField[];
    content?: {
      sections: ISection[];
    };
    sidebar?: IAssetSidebar;
    secFilingsLinks?: { label: string; link: string }[];
  };
  copyrightType?: string;
  copyrightShare?: number;
  perkType?: string;
  relatedRelease?: string;
  percentCopyright: number;
  securitizeTokenId?: string;
  parentId?: number;
  upc?: string;
  releaseTitle?: string;
  artistName?: string;
  royaltyCopyrightShare?: number;
  lastInvestmentLinked?: InvestmentRaw;
}

export interface ICreateAsset {
  id: number;
  unitName: string;
  type: AssetType;
  nftCount: number;
  percentCopyright: number;
  securitizeTokenId?: string | null;
  parentId?: number | null;
  upc?: string | null;
  releaseTitle?: string | null;
  artistName?: string | null;
  royaltyCopyrightShare?: number | null;
  custom?: {
    title?: string;
    description?: string;
    artist?: string;
    fields?: IAssetField[];
    content?: {
      sections: ISection[];
    };
    sidebar?: IAssetSidebar;
  };
}

export interface AssetContractMetadata extends Asset {
  contracts: {
    id?: number;
    status: ContractStatus;
    assetId: number;
    amount: bigint;
  }[];
}

export interface Contract {
  id: number;
  address: string;
  asset_id: number;
  amount: string;
  receiver: string;
  issuer: string;
  status: ContractStatus;
  created_at: Date;
  updated_at: Date;
}

export interface IAssetSidebar {
  items: ISidebarItem[];
}

export interface ISidebarItem {
  title: string;
  description: string;
  content: ISidebarItemContent[];
}

export enum SidebarItemContentType {
  iconList = 'iconList',
  timeline = 'timeline',
  labels = 'labels',
  piechart = 'pieChart',
}

export interface ISidebarItemContent {
  order: number;
  type: SidebarItemContentType;
  description: string;
  items:
    | IContentLabelListItem[]
    | IContentIconListItem[]
    | IContentTimeLineItem[]
    | IContentPieChartItem[];
}

export interface IContentLabelListItem {
  name: string;
  order: number;
  value: string;
}

export interface IContentIconListItem {
  icon: string;
  title: string;
  description: string;
}

export interface IContentTimeLineItem {
  order: number;
  description: string;
}

export interface IContentPieChartItem {
  name: string;
  percent: number;
}

export interface AssetListResponse {
  status: string;
  totalCount: number;
  assets: Asset[];
}

export enum PoolType {
  preSale = 'pre-sale',
  fund = 'fund',
  loan = 'loan',
}

export interface StakeData {
  amount: BigNumber;
  stake: number;
  apy?: number;
  dailyProfits?: string;
}

export interface Pool {
  id: number;
  title: string;
  summary: string;
  description: string;
  type: PoolType;
  hasEntered?: boolean;
  currencyUnitName: string;
  predictedAPY: number;
  investmentHoldPeriod: number;
  currency?: string;
  active?: boolean;
  earned?: number;
  currentPool?: number;
  shareOfPool?: number;
  yourBalance?: number;
  tickets?: number;
  apyRange: string;
  maxTokens: number;
  usedTokens: number;
  saleDate: Date;
  allTickets: number;
  yourTickets: number;
  totalWinners: number;
  isSale: boolean;
  isEarlyAccess: boolean;
  minStakePeriod: number;
  maxStakePeriod: number;
  apy: number;
  dailyProfits: number;
  availableTokens: number;
  stakes?: PoolStakes[];
  optIns?: OptIns;
  currencyId?: number;
}

export interface ICreatePool {
  appId: number;
  address?: string | null;
  title: string;
  description: string;
  type: PoolVersion;
  summary?: string | null;
  versionLabel?: string | null;
}

export interface OptIns {
  [key: string]: OptInDetails;
}

export interface OptInDetails {
  pool: string;
  account: string;
  tokenAmountAvailableToStake?: number;
  walletHasOptIn: boolean;
}

export interface PoolStakes {
  id: number;
  stakeId: number;
  amount: number;
  stakeDate: Date;
  earned: number;
  withdrawDate: Date;
  profitTokens: number;
  profitTime: Date;
}

export interface PoolConditions {
  apy: string;
  maxTokens: number;
  usedTokens: number;
}

export interface PoolSale {
  saleDate: Date;
  allTickets: number;
  yourTickets: number;
  totalWinners: number;
  isSale: boolean;
  isEarlyAccess: boolean;
}

export interface PoolStake {
  minStakePeriod: number;
  maxStakePeriod: number;
  apy: number;
  dailyProfits: number;
  availableTokens: number;
  stakes: PoolStakeDetails[];
}

export interface PoolStakeDetails {
  amount: number;
  stakeDate: Date;
  earned: number;
  withdrawDate: Date;
  profitTokens: number;
  profitTime: string;
}

export enum PoolApplicationStatusType {
  pending = 'pending',
  accepted = 'accepted',
}

export interface PoolApplication {
  id: number;
  status: PoolApplicationStatusType;
  pool: Pool;
}

export interface IWalletContext {
  state: IWalletContextState;
  reloadWallet: () => void;
  initPeraWallet: () => void;
  initMyAlgoWallet: () => void;
  disconnectWallet: () => void;
}

export interface APIResponse<T> {
  data: T;
}

export type ResponsePageable<T> = {
  count: number;
  limit: number;
  offset: number;
  items: T;
};

export type SortOption = {
  direction: 'desc' | 'asc';
  column: string;
};

export interface RoyaltiesSalesFilter {
  date: Option[];
  release: Option[];
  individual: boolean;
}

export interface RoyaltiesSalesFullOverviewCountry {
  name: string;
  streams: number;
  earning: number;
  percent: number;
}
export interface RoyaltiesSalesFullOverviewRelease {
  title: string;
  artist: string;
  streams: number;
  earning: number;
  image: string;
}
export interface RoyaltiesSalesFullOverviewStore {
  store: string;
  image: string;
  streams: number;
  earning: number;
  percent: number;
}
export interface RoyaltiesSalesFullOverviewMonth {
  date: Date;
  streams: number;
  earning: number;
}
export interface RoyaltiesSalesFullOverview {
  releases: ResponsePageable<RoyaltiesReleasesSalesSummary>;
  countries: ResponsePageable<RoyaltiesCountriesSalesSummary>;
  stores: ResponsePageable<RoyaltiesStoresSalesSummary>;
  months: ResponsePageable<RoyaltiesMonthsSalesSummary>;
}
export interface RoyaltiesSalesResponseData {
  filter: RoyaltiesSalesFilter;
  fullOverview: RoyaltiesSalesFullOverview;
}

export interface RoyaltiesPayoutInfo {
  usdcAsset: {
    id: number;
    optedIn: boolean;
  };
  availableBalance: MicroUSDC;
  totalEarned: MicroUSDC;
}
export interface RoyaltiesPayoutEntry {
  amount: MicroUSDC;
  requestedOn: Date;
  transactionId: string;
}
export interface RoyaltiesPayoutSummary {
  info: RoyaltiesPayoutInfo;
  history: RoyaltiesPayoutEntry[];
}

export const AnalyticsTrendsViewMap = {
  STREAM: { value: 'STREAM', label: 'Stream' },
  DOWNLOAD: { value: 'DOWNLOAD', label: 'Download' },
};
export const AnalyticsTrendsTypeMap = {
  STORES: { value: 'STORES', label: 'Stores' },
  COUNTRIES: { value: 'COUNTRIES', label: 'Countries' },
  RELEASES: { value: 'RELEASES', label: 'Releases' },
  TRACKS: { value: 'TRACKS', label: 'Tracks' },
  ARTISTS: { value: 'ARTISTS', label: 'Artists' },
};
export const AnalyticsTrendsDateMap = {
  WEEK: { value: 7, label: '1 Week' },
  MONTH: { value: 30, label: '1 Month' },
};
export interface AnalyticsFilter {
  releases: Option[];
}
export interface AnalyticsChartData {
  series: { name: string; color: string; data: number[] }[];
  categories: string[];
}
export interface AnalyticsTableEntry {
  color?: string;
  name: string;
  total: number;
}
export interface AnalyticsResponseData {
  filter: AnalyticsFilter;
  chart: AnalyticsChartData;
  data: { data: any[] };
}

export type VestingContract = {
  address: string;
  assetId: number;
  status: ContractStatus;
  optedIn: boolean;
  startAt: Date;
  endAt: Date;
  preClaimed?: MinorOPUL;
  totalOpul: MinorOPUL;
  lockedOpul: MinorOPUL;
  claimedOpul: MinorOPUL;
  toClaimOpul: MinorOPUL;
  dailyDistribution: MinorOPUL;
};

export type VestingsResponseData = {
  contracts: VestingContract[];
};

export type WithdrawTransactionsWithAppCallEncoded = {
  appCallTransactionEncoded: number[];
  assetTransferTransactionSigned: {
    txID: string;
    blob: number[];
  };
  defundTransactionSigned?: {
    txID: string;
    blob: number[];
  };
};

export type WithdrawTransactionsWithAppCallToSign = {
  appCallTransaction: algosdk.Transaction;
  assetTransferTransactionSigned: {
    txID: string;
    blob: number[];
  };
  defundTransactionSigned?: {
    txID: string;
    blob: number[];
  };
};

export type RoyaltieSalesReleases = Readonly<
  {
    id: string;
    title: string;
  }[]
>;
export type RoyaltiesCountriesSalesSummary = Readonly<
  {
    countryCode: string;
    countryName: string;
    earning: number;
    units: number;
    percent: number;
  }[]
>;
export type RoyaltiesStoresSalesSummary = Readonly<
  {
    storeName: string;
    earning: number;
    units: number;
    percent: number;
  }[]
>;
export type RoyaltiesReleasesSalesSummary = Readonly<{
  releaseTitle: string;
  artistName: string;
  units: number;
  earning: number;
  percent: number;
  image: string;
}>[];
export type RoyaltiesMonthsSalesSummary = Readonly<{
  month: Date;
  units: number;
  earning: number;
  percent: number;
}>[];
export type RoyaltiesSalesSummaryResponseData = {
  releases: RoyaltieSalesReleases;
  countriesSalesSummary: RoyaltiesCountriesSalesSummary;
  storesSalesSummary: RoyaltiesStoresSalesSummary;
  releasesSalesSummary: RoyaltiesReleasesSalesSummary;
  monthsSalesSummary: RoyaltiesMonthsSalesSummary;
};

export type StakeInformation = {
  amount: string;
  lockup: string;
  timestamp: string;
  annualPercentageYield: string;
  reward: string;
  unlockPeriod: string;
  projectedStake: string;
  earnedTokens: string;
  index: number;
};

export type HistoryStakeItem = {
  index: number;
  annualPercentageYield: number;
  lockup: string;
  currentBalance: string;
  earnedTokens: string;
  projectedStake: string;
  stakeAmount: string;
  stakeTimestamp: string;
  transactionId: string;
  type: string;
  unlockPeriod: string;
  withdrawnAmount: string;
  withdrawnTimestamp: string;
};

export enum PoolVersion {
  v1 = 'opul_staking',
  v2 = 'opul_staking_v2',
}

export type PoolDetails = {
  tokenAmountAvailableToStake: number;
  activeStakes: StakeInformation[];
  availableTickets?: string;
  history?: HistoryStakeItem[];
  account: {
    address: string;
    amount: number;
    // "amountWithoutPendingRewards": 41573000,
    // "appsLocalState": [

    // ],
    // "pendingRewards": 0,
    // "rewardBase": 27521,
    // "appsTotalSchema": {
    //   "num-byte-slice": 0,
    //   "num-uint": 0
    // },
    // "rewards": 0,
    // "round": 22509576,
    // "status": "Offline"
  };
  pool: {
    id: number;
    appId: number;
    title: string;
    description: string;
    summary: string;
    type: PoolVersion;
    address: string;
    maxStakePeriod: number;
    minStakePeriod: number;
    maxAnnualPercentageYield: number;
    minAnnualPercentageYield: number;
    endTimestamp: string;
    maxShare: bigint;
    maxPoolSize: bigint;
    poolSize: number;
    tokenId: number;
    maxWalletStakes: number;
    stakes: PoolStake[];
    versionLabel?: string;
    latestVersionLabel: string;
  };
  walletHasOptIn: boolean;
  totalStaked: string;
  totalCurrentBalance: string;
  // "stakeAvailableSlots": [

  // ]
};

export type PoolList = {
  id: number;
  appId: number;
  title: string;
  description: string;
  type: PoolVersion;
  address: null;
  maxStakePeriod: number;
  minStakePeriod: number;
  maxAnnualPercentageYield: number;
  minAnnualPercentageYield: number;
  endTimestamp: string;
  maxShare: number;
  maxPoolSize: number;
  poolSize: number;
  tokenId: number;
  tokenUnitName: string;
  maxWalletStakes: number;
  versionLabel?: string;
  latestVersionLabel: string;
  myStaked: { amount: string }[];
};

export type GetStakeTransactionResponse = {
  data: {
    assetPaymentTransaction: number[];
    stakeIndexTransaction: number[];
  };
};

export enum RaffleStatus {
  CREATED = 'CREATED',
  SCHEDULED = 'SCHEDULED',
  FINISHED = 'FINISHED',
}

export interface IRaffleEntry {
  id: number;
  raffleId: number;
  ticketsSpentAmount: number;
  walletAddress: string;
  createdAt: string;
  updatedAt: string;
  hasWon: boolean;
  hasClaimed: boolean;
}

export interface IAdminRaffle {
  id: number;
  title: string;
  description: string;
  image: string;
  endTimestamp: string;
  numberOfWinners: number;
  startTimestamp: string;
  maxTicketAmount: number;
  investmentId?: number;
  createdAt: string;
  totalTicketsSpentAmount: number;
  status: RaffleStatus;
  hidden: boolean;
}

export interface IRaffle {
  id: number;
  image: string;
  title: string;
  description: string;
  startTimestamp: string;
  endTimestamp: string;
  maxTicketAmount: number;
  numberOfWinners: number;
  totalTicketsSpentAmount: number;
  walletRaffleEntry: IRaffleEntry;
  winners: IRaffleEntry[];
  createdAt: string;
  updatedAt: string;
  hidden: boolean;
  investmentId?: number;
}

export interface IRaffleCreate {
  title: string;
  description: string;
  image: File;
  endTimestamp: string;
  numberOfWinners: number;
  startTimestamp: string;
  maxTicketAmount: number;
  investmentId: number | null;
  hidden: boolean;
}
export enum InvestmentStatus {
  UPCOMING = `Upcoming`,
  PRE_SALE = `Pre-sale`,
  ACTIVE = `Active`,
  SOLD_OUT = `Sold Out`,
  COMPLETED = `Completed`,
}

export type FAQQuestion = {
  statement: string;
  answer: string;
};
export type PreviousRelease = {
  track: string;
  releaseDate: Date;
  overallStreams: number;
};
export type ExclusivePerk = {
  value: number;
  legend: string;
  description: string;
  thumbnail: {
    url: string;
  };
};
export type IncludedCatalogItem = {
  title: string;
  legend: string;
  description: string;
  thumbnail: {
    url: string;
  };
  preSaleLink?: string;
  playLink?: string;
};
export type ExclusivePerkByLevel = {
  badge: {
    url: string;
  };
  name: string;
  value: number;
  items: {
    title: string;
    thumbnail: {
      url: string;
    };
  }[];
};
export type WalletInvestmentPurchase = {
  amount: number;
  settlementAmount: number;
  createdAt: Date;
};
export type Investment = {
  id: number;
  cmsId: string;
  saleTitle: string;
  saleSubtitle: string;
  status: InvestmentStatus;
  preSaleStartDate?: Date;
  saleStartDate: Date;
  saleEndDate: Date;
  releaseTitle: string;
  artistName: string;
  investmentTargetAmount: number;
  purchaseAmountIncrement: number;
  fundsRaised: number;
  fundsRaisedPercent: number;
  dealType: string;
  investors: number;
  rewardsShare: number;
  minIndividualInvestment: number;
  maxIndividualInvestment: number;
  preSaleMinIndividualInvestment: number;
  preSaleMaxIndividualInvestment: number;
  maxIndividualInvestmentReached: boolean;
  soldOut: boolean;
  purchaseHistory: WalletInvestmentPurchase[];
  asset: Asset & {
    nftDescription: {
      name: string;
      image: string;
      description: string;
    };
  };
};

export type InvestmentRaw = {
  id: number;
  updatedAt: number;
  createdAt: number;
  hidden: boolean;
  cmsId: string;
  preSaleMinIndividualInvestment: number | null;
  preSaleMaxIndividualInvestment: number | null;
  minIndividualInvestment: number;
  maxIndividualInvestment: number;
  investmentTargetAmount: number;
  preSaleStartDate: string | null;
  saleStartDate: string;
  saleEndDate: string;
  dealType: string;
  investmentWhitelist: string[];
  assetId: number;
  crmTagId?: string | null;
  purchaseAmountIncrement: number;
};
export type ICreateInvestment = Omit<
  InvestmentRaw,
  'id' | 'updatedAt' | 'createdAt'
>;

export type InvestmentDetails = Investment & {
  spotifyStats?: number;
  soundcloudStats?: number;
  instagramStats?: number;
  rewardsApy?: string;
  preSaleWinners?: number;
  mftDistributionDate: Date;
  nextRewardDistribution: Date;
  sampleTrack: string;
  pitchVideo: string;
  highlightsContent: string;
  aboutTheArtistContent: string;
  posts: { content: string }[];
  previousReleases: PreviousRelease[];
  exclusivePerks: ExclusivePerk[];
  includedCatalog: IncludedCatalogItem[];
  exclusivePerksByLevel: ExclusivePerkByLevel[];
  questions: FAQQuestion[];
  cmsId: number;
  crmTagId: number;
  isInWhiteList?: boolean;
  isAllowedToBuy?: boolean;
  disabledCountries?: string[];
  purchaseHistory: WalletInvestmentPurchase[];
};

export type InvestmentAvailability = {
  amountAvailable: number;
  amountReserved: number;
};

export enum PaymentMethodOptions {
  OPUL = 'OPUL',
  CREDI_CARD = 'CREDIT_CARD',
  CRYPTO = 'CRYPTO',
}

export enum InvestmentPurchaseStatus {
  RESERVED = 'RESERVED',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}

export type PaymentIntent = {
  transactionEncoded: number[];
  payment: {
    paymentMethod: PaymentMethodOptions;
    balanceEnough: boolean;
    expirationDate: Date;
    externalId: string;
    exchangeRate: number;
    settlementAmount: number;
  };
};

export type PaymentTransactionState = {
  externalId: string;
  status: InvestmentPurchaseStatus;
};

export enum IssuanceType {
  REGULAR = 'REGULAR',
  ROYALTY = 'ROYALTY',
}

export enum IssuanceFileStatus {
  NEW = 'NEW',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

type IssuanceAmount = {
  asBigInt: bigint;
  asDecimal: string;
};

type IssuanceMetadata = {
  assetId: number;
  fundingAddress: string;
  receiver: string;
  amount: IssuanceAmount;
  assetName: string;
};

export enum IssuanceContractStateItemStatus {
  PENDING = 'PENDING',
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
}

export type IssuanceContractState = {
  contractAddress: string;
  issuanceMetadata: IssuanceMetadata;
  amountIssued: IssuanceAmount;
  status: IssuanceContractStateItemStatus;
};

export type IssuanceCurrentState = IssuanceContractState[];
