import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/system';
import { CircularProgress } from '@mui/material';
import {
  LocalStorageWalletKey,
  LocalStorageWalletTypeKey,
} from '@opulous/web/src/constant/keys';
import {
  setLocalStorage,
  clearLocalStorage,
  getLocalStorage,
} from '@opulous/web/src/utils';
import WalletContext from '@opulous/web/src/context/context';
import myAlgo from '@opulous/web/src/services/my-algo';
import { WalletTypeEnum } from '@opulous/web/src/shared/types';
import { PeraWalletConnect } from '@perawallet/connect';
import * as AssetsService from '@opulous/web/src/services/assets';
import { Box, Container, CssBaseline } from '@mui/material';
import Header from '@opulous/web/src/components/layout/Header';

const CircularProgressContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface IWalletProvider {
  children?: React.ReactNode;
}

const RoyaltyValidMfts = [
  725937713, 834249983, 871206880, 145275704, 145275785, 106819656,
];
async function checkIfWalletHoldsMFTAssets(address: string) {
  const allAvailableAssetMFTs =
    await AssetsService.fetchAllAvailableAssetMFTs();

  const allValidMFTs = [
    ...RoyaltyValidMfts,
    ...allAvailableAssetMFTs.map(it => it.assetId),
  ];
  const accountInformation = await myAlgo.getAccountInformation(address);
  const hasMFTAssets = accountInformation.assets.some(it =>
    allValidMFTs.includes(it['asset-id']),
  );
  return hasMFTAssets;
}

const WalletProvider: React.FC = ({ children }: IWalletProvider) => {
  const peraWalletConnectRef = useRef(new PeraWalletConnect());
  const [error, setError] = useState<string>('');
  const [wallet, setWallet] = useState<string>(
    getLocalStorage(LocalStorageWalletKey, ''),
  );
  const [walletType, setWalletType] = useState<WalletTypeEnum | undefined>(
    (getLocalStorage(LocalStorageWalletTypeKey, '') as WalletTypeEnum | '') ||
      undefined,
  );
  const [timestamp, setTimeStamp] = useState(Date.now());
  const [isReady, setIsReady] = useState(false);
  const [hasMFTs, setHasMFTs] = useState(false);

  const peraWalletConnect = peraWalletConnectRef.current;

  const disconnectWallet = async () => {
    const isPeraWallet = walletType === WalletTypeEnum.peraWallet;
    setWallet('');
    setWalletType(undefined);
    if (isPeraWallet) {
      Object.assign(peraWalletConnectRef, { current: new PeraWalletConnect() });
      await peraWalletConnect.disconnect();
    }
  };

  const initMyAlgoWallet = async () => {
    try {
      const addresses = await myAlgo.connect();
      if (!addresses?.length) {
        return setError('No accounts available');
      }

      const address = addresses[0];
      const hasMFTAssets = await checkIfWalletHoldsMFTAssets(address);
      setHasMFTs(hasMFTAssets);
      setWalletType(WalletTypeEnum.myAlgoWallet);
      setWallet(address);
      setError('');
      setIsReady(true);
    } catch (err) {
      Sentry.captureException(err);
      setError('An unknown error ocurred while connecting to My Algo Wallet');
    }
  };

  const initPeraWallet = async () => {
    try {
      const addresses = await peraWalletConnect.connect();
      peraWalletConnect.connector?.on('disconnect', disconnectWallet);

      if (!addresses?.length) {
        return setError('No accounts available');
      }

      const address = addresses[0];
      const hasMFTAssets = await checkIfWalletHoldsMFTAssets(address);
      setHasMFTs(hasMFTAssets);
      setWalletType(WalletTypeEnum.peraWallet);
      setWallet(address);
      setError('');
      setIsReady(true);
    } catch (err) {
      if ((err as any)?.data?.type === 'CONNECT_MODAL_CLOSED') {
        // NOTE: When user closes the modal, the `PeraWalletConnect.connect` library function
        //       will throw this error. This is just a way to abort all the follow-up operations,
        //       but this is not an actual error.
        // Ref: https://codesandbox.io/s/perawallet-connect-react-demo-zlvokc?file=/src/App.js
        return;
      }
      Sentry.captureException(err);
      throw err;
    }
  };

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: '<WalletProvider /> : useEffect, [wallet, walletType]',
      message: 'Updating local storage',
      type: 'info',
      data: {
        wallet,
        walletType,
      },
    });
    if (wallet) {
      Sentry.setUser({ id: wallet });
      setLocalStorage(LocalStorageWalletKey, wallet);
      (async () => {
        const hasMFTAssets = await checkIfWalletHoldsMFTAssets(wallet);
        setHasMFTs(hasMFTAssets);
        setIsReady(true);
      })();
    } else {
      setIsReady(true);
      Sentry.setUser(null);
      clearLocalStorage(LocalStorageWalletKey);
    }
    if (walletType) {
      Sentry.setTag('op_walletType', walletType);
      setLocalStorage(LocalStorageWalletTypeKey, walletType);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Sentry.setTag('op_walletType', undefined!);
      clearLocalStorage(LocalStorageWalletTypeKey);
    }
  }, [wallet, walletType]);

  return (
    <WalletContext.Provider
      value={{
        state: {
          isReady,
          timestamp,
          hasMFTs,
          wallet,
          walletType,
          connector: peraWalletConnect,
          error,
        },
        reloadWallet: () => setTimeStamp(Date.now()),
        initPeraWallet,
        initMyAlgoWallet,
        disconnectWallet,
      }}
    >
      {isReady ? (
        children
      ) : (
        <Box>
          <CssBaseline />
          <Header />
          <Container maxWidth="xl">
            <div style={{ width: '100%', marginTop: '100px' }}>
              <CircularProgressContainer>
                <CircularProgress />
              </CircularProgressContainer>
            </div>
          </Container>
        </Box>
      )}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
