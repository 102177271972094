import React from 'react';
import { Outlet, useLocation } from 'react-router';
import { Box, Container, CssBaseline } from '@mui/material';
import Header from '@opulous/web/src/components/layout/Header';
import DashboardBase from '@opulous/web/src/pages/admin/DashboardBase';
import MyAlgoWarningBanner from './MyAlgoWarningBanner';
import { SignerAppProvider } from '@opulous/web/src/context/SignerAppContext';

function checkIsAdminDashboard(pathName: string): boolean {
  return pathName === '/admin' || pathName.startsWith('/admin/');
}

function checkIsAdminLoginPage(pathName: string): boolean {
  return pathName === '/admin/login' || pathName === '/admin/login/';
}

function checkIsExchangePage(pathName: string): boolean {
  return pathName === '/exchange' || pathName === '/exchange/';
}

const Layout: React.FC = () => {
  const location = useLocation();

  if (checkIsAdminLoginPage(location.pathname)) {
    return <Outlet />;
  }

  if (checkIsAdminDashboard(location.pathname)) {
    return (
      <SignerAppProvider>
        <DashboardBase>
          <Outlet />
        </DashboardBase>
      </SignerAppProvider>
    );
  }

  if (checkIsExchangePage(location.pathname)) {
    return (
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <CssBaseline />
        <Box flexGrow="0">
          <Header />
        </Box>
        <Box flex="1">
          <Outlet />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <CssBaseline />
      <Header />
      <Container maxWidth="xl">
        <MyAlgoWarningBanner />
        <Outlet />
      </Container>
    </Box>
  );
};

export default Layout;
