import axios, { AxiosResponse } from 'axios'
import { IAdminRaffle, IRaffle, IRaffleCreate } from 'src/shared/types'
import { getApiBaseUrl } from '../../axios-setup'
import { getAdminAuthorizationHeader } from '../admin'

export interface IGetRafflesResponse {
  data: {
    raffles: IRaffle[]
    numAvailableTickets: string
  }
}

export interface IGetAvailableTicketsResponse {
  data: number
}

export interface IRaffleImageUploadUrlResponse {
  data: { uploadUrl: string, imageUrl: string }
}

export const getRaffles = async (
  walletAddress: string
): Promise<AxiosResponse<IGetRafflesResponse>> => {
  return axios.get(
    `${getApiBaseUrl()}/api/raffles?${new URLSearchParams({
      ...(walletAddress ? { walletAddress } : null),
    }).toString()}`
  )
}

export const addTickets = async (
  walletAddress: string,
  raffleId: number,
  numTickets: number
): Promise<void> => {
  await axios({
    method: 'POST',
    url: `${getApiBaseUrl()}/api/raffles/${encodeURIComponent(raffleId)}/add-tickets`,
    data: {
      walletAddress,
      ticketsAmount: numTickets,
    },
  });
}

export const claimRaffleRewards = async (
  walletAddress: string,
  raffleId: number,
  email: string,
  verificationCode: string,
): Promise<void> => {
  await axios({
    method: 'POST',
    url: `/api/raffles/${encodeURIComponent(raffleId)}/claim`,
    data: {
      walletAddress,
      email,
      oneTimePassword: verificationCode,
    },
  });
}

export const sendVerificationCode = async (
  walletAddress: string,
  raffleId: number,
  email: string,
): Promise<void> => {
  await axios({
    method: 'POST',
    url: `/api/raffles/${encodeURIComponent(raffleId)}/claim/request-otp`,
    data: { walletAddress, email },
  });
}

export const adminGetRaffles = async (
  page: number,
  pageSize: number,
  searchText?: string,
): Promise<{
  count: number
  page: number
  pageSize: number
  data: IAdminRaffle[]
}> => {
  const { data: { data } } = await axios({
    method: 'GET',
    url: `/api/admin/raffles?${new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
      ...(searchText ? { searchText } : null),
    }).toString()}`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

export const adminGetRaffle = async (
  id: number,
): Promise<IAdminRaffle|null> => {
  try {
    const { data: { data } } = await axios({
      method: 'GET',
      url: `/api/admin/raffles/${encodeURIComponent(id)}`,
      headers: await getAdminAuthorizationHeader(),
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    }
    throw err;
  }
}

export const adminCreateRaffle = async (
  raffle: IRaffleCreate,
) => {
  const { data: { data: { uploadUrl, imageUrl } } }: AxiosResponse<IRaffleImageUploadUrlResponse> = await axios({
    method: 'GET',
    url: `/api/admin/raffles/image-upload-url`,
    headers: await getAdminAuthorizationHeader(),
  });
  await axios({
    method: 'PUT',
    url: uploadUrl,
    data: raffle.image,
  });

  await axios({
    method: 'POST',
    url: '/api/admin/raffles',
    data: {
      title: raffle.title,
      description: raffle.description,
      image: imageUrl,
      endTimestamp: raffle.endTimestamp,
      numberOfWinners: raffle.numberOfWinners.toString(),
      startTimestamp: raffle.startTimestamp,
      maxTicketAmount: raffle.maxTicketAmount.toString(),
      investmentId: raffle.investmentId?.toString() ?? null,
      hidden: raffle.hidden,
    },
    headers: await getAdminAuthorizationHeader(),
  });
}

export const adminEditRaffle = async (
  id: number,
  raffle: Partial<IRaffleCreate>,
) => {
  const data = {};
  raffle.title !== undefined && Object.assign(data, { title: raffle.title });
  raffle.description !== undefined && Object.assign(data, { description: raffle.description });
  if (raffle.image !== undefined) {
    const { data: { data: { uploadUrl, imageUrl } } }: AxiosResponse<IRaffleImageUploadUrlResponse> = await axios({
      method: 'GET',
      url: `/api/admin/raffles/image-upload-url`,
      headers: await getAdminAuthorizationHeader(),
    });
    await axios({
      method: 'PUT',
      url: uploadUrl,
      data: raffle.image,
    });

    Object.assign(data, { image: imageUrl });
  }
  raffle.endTimestamp !== undefined && Object.assign(data, { endTimestamp: raffle.endTimestamp });
  raffle.numberOfWinners !== undefined && Object.assign(data, { numberOfWinners: raffle.numberOfWinners.toString() });
  raffle.startTimestamp !== undefined && Object.assign(data, { startTimestamp: raffle.startTimestamp });
  raffle.maxTicketAmount !== undefined && Object.assign(data, { maxTicketAmount: raffle.maxTicketAmount.toString() });
  raffle.investmentId !== undefined && Object.assign(data, { investmentId: raffle.investmentId?.toString() ?? null });
  raffle.hidden !== undefined && Object.assign(data, { hidden: raffle.hidden });

  await axios({
    method: 'PATCH',
    url: `/api/admin/raffles/${encodeURIComponent(id)}`,
    data,
    headers: await getAdminAuthorizationHeader(),
  });
}

export const adminDeleteRaffle = async (
  id: number,
) => {
  await axios({
    method: 'DELETE',
    url: `/api/admin/raffles/${encodeURIComponent(id)}`,
    headers: await getAdminAuthorizationHeader(),
  });
}

export const adminCloneRaffle = async (
  id: number,
): Promise<IAdminRaffle> => {
  const { data: { data } } = await axios({
    method: 'POST',
    url: `/api/admin/raffles/${encodeURIComponent(id)}/clone`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

export const adminGetRaffleWinners = async (
  id: number,
): Promise<{ walletAddress: string, hasClaimed: boolean }[]> => {
  const { data: { data } } = await axios({
    method: 'GET',
    url: `/api/admin/raffles/${encodeURIComponent(id)}/winners`,
    headers: await getAdminAuthorizationHeader(),
  });
  return data;
}

