import { ReactElement, useContext, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/system';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useFeatureFlags } from '@opulous/web/src/hooks/useFeatureFlags';
import WalletContext from '@opulous/web/src/context/context';
import ThemeColorModeContext, {
  ThemeMode,
} from 'src/context/ThemeColorModeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import config from '@opulous/web/src/config';

const DesktopMenuWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  marginRight: theme.spacing(7.75),
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: theme.spacing(30),
    padding: theme.spacing(1),
  },
}));

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const DrawerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
}));

const StyledLink = styled(Link)(({ theme, active }: LinkProps) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[900],
  fontSize: theme.spacing(2.25),
  fontWeight: 600,
  textDecoration: 'none',
  margin: theme.spacing(0, 0.5),
  padding: theme.spacing(3.5, 2, 3, 2),
  whiteSpace: 'nowrap',
  borderBottom: `3px solid ${
    JSON.parse(active) ? theme.palette.primary.main : 'transparent'
  }`,
}));

const TabItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[900],
  fontSize: theme.spacing(2.25),
  fontWeight: 600,
  textDecoration: 'none',
  margin: theme.spacing(0, 0.5),
  padding: theme.spacing(3.5, 2, 3, 2),
  whiteSpace: 'nowrap',
  cursor: 'pointer',
}));

const StyledSubLink = styled(Link)(({ theme, active }: LinkProps) => ({
  display: 'flex',
  alignItems: 'start',
  width: 'auto',
  color: JSON.parse(active)
    ? theme.palette.primary.main
    : theme.palette.grey[900],
  fontSize: theme.spacing(2.25),
  fontWeight: 600,
  textDecoration: 'none',
  margin: theme.spacing(0, 0.8),
  padding: theme.spacing(0, 0),
  whiteSpace: 'nowrap',
}));
const StyledSubExternalLink = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  width: 'auto',
  color: theme.palette.grey[900],
  fontSize: theme.spacing(2.25),
  fontWeight: 600,
  textDecoration: 'none',
  margin: theme.spacing(0, 0.8),
  padding: theme.spacing(0, 0),
  whiteSpace: 'nowrap',
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 900,
  paddingLeft: theme.spacing(1.2),
  paddingRight: theme.spacing(3),
  width: '100%',
}));

const MobileLink = styled(Link)(({ theme, active }: LinkProps) => ({
  fontSize: theme.spacing(2),
  fontWeight: 600,
  textDecoration: 'none',
  margin: theme.spacing(1, 0),
  color: JSON.parse(active) ? theme.palette.grey[900] : theme.palette.grey[500],
}));
const MobileExternalLink = styled('a')(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 600,
  textDecoration: 'none',
  margin: theme.spacing(1, 0),
  color: theme.palette.grey[500],
}));

const DrawerItem = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 600,
  margin: theme.spacing(1, 0),
  color: theme.palette.grey[500],
}));

const BackIcon = styled(KeyboardArrowLeftIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

type LinkProps = {
  theme: Theme;
  active: string;
  to: string;
};

type Props = {
  isTablet: boolean;
  openSidebar: boolean;
  onCloseSidebar: () => void;
};
export default function TabMenuList({
  isTablet,
  openSidebar,
  onCloseSidebar,
}: Props): ReactElement {
  const themeColorModeContext = useContext(ThemeColorModeContext);
  const {
    state: { hasMFTs },
  } = useContext(WalletContext);
  const location = useLocation();
  const theme: Theme = useTheme();
  const { isActive } = useFeatureFlags();

  const handleToggle = (
    previousValue: boolean,
    setCallback: (value: boolean) => void,
  ) => {
    setCallback(!previousValue);
    return false;
  };
  const handleClose = (setCallback: (value: boolean) => void) => {
    setCallback(false);
  };

  const isActivePath = (path: string) =>
    `${
      (path === '/' && path === location.pathname) ||
      (path !== '/' && location.pathname.indexOf(path) > -1)
    }`;

  const menus = [
    {
      label: 'AI',
      ref: useRef(null),
      path: config.env.MENU_AI_URL,
      external: true,
    },
    {
      label: 'Finance',
      ref: useRef(null),
      items: [
        {
          label: 'OVAULT',
          path: config.env.MENU_FINANCE_OVAULT_URL,
          external: true,
        },
      ],
    },
    {
      label: 'MFT',
      ref: useRef(null),
      items: [
        {
          label: 'Assets',
          path: '/assets',
        },
        ...(hasMFTs
          ? [
              {
                label: 'Rewards',
                path: '/rewards',
              },
            ]
          : []),
        {
          label: 'Sales',
          path: '/mfts',
        },
        {
          label: 'Raffles',
          path: '/raffles',
        },
      ],
    },
    {
      label: 'OPUL Token',
      ref: useRef(null),
      items: [
        {
          label: 'Buy',
          path: config.env.MENU_OPUL_TOKEN_BUY_URL,
          external: true,
        },
        {
          label: 'Stake',
          path: '/pools',
        },
        {
          label: 'About OPUL',
          path: config.env.MENU_OPUL_TOKEN_ABOUT_URL,
          external: true,
        },
      ],
    },
    {
      label: 'Resources',
      ref: useRef(null),
      items: [
        {
          label: 'Blog',
          path: config.env.MENU_RESOURCES_BLOG_URL,
          external: true,
        },
        {
          label: 'Help Center',
          path: config.env.MENU_RESOURCES_HELP_CENTER_URL,
          external: true,
        },
        {
          label: 'About Opulous',
          path: config.env.MENU_RESOURCES_ABOUT_OPULOUS_URL,
          external: true,
        },
        {
          label: 'Release an MFT',
          path: config.env.MENU_RESOURCES_RELEASE_MFT_URL,
          external: true,
        },
      ],
    },
    {
      label: 'Community',
      ref: useRef(null),
      items: [
        {
          label: 'Ambassadors',
          path: config.env.MENU_COMMUNITY_AMBASSADORS_URL,
          external: true,
        },
        {
          label: 'X (Twitter)',
          path: config.env.MENU_COMMUNITY_TWITTER_URL,
          external: true,
        },
        {
          label: 'Instagram',
          path: config.env.MENU_COMMUNITY_INSTAGRAM_URL,
          external: true,
        },
        {
          label: 'LinkedIn',
          path: config.env.MENU_COMMUNITY_LINKEDIN_URL,
          external: true,
        },
        {
          label: 'Telegram',
          path: config.env.MENU_COMMUNITY_TELEGRAM_URL,
          external: true,
        },
        {
          label: 'Youtube',
          path: config.env.MENU_COMMUNITY_YOUTUBE_URL,
          external: true,
        },
      ],
    },
  ];

  const [menusOpened, setMenusOpened] = useState(menus.map(() => false))

  return isTablet ? (
    <StyledDrawer
      open={openSidebar}
      onClose={onCloseSidebar}
      data-testid="tabmenulist-mobile"
    >
      <DrawerHeader>
        <IconButton onClick={onCloseSidebar}>
          <BackIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        {isActive('darkTheme') && (
          <DrawerItem onClick={themeColorModeContext.toggle}>
            {themeColorModeContext.mode === ThemeMode.DARK ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </DrawerItem>
        )}
        {menus
          .reduce(
            (all, it) => [...all, ...(it.items ?? [it])],
            [] as { path: string; label: string; external?: boolean }[],
          )
          .map((it, ix) =>
            it.external ? (
              <MobileExternalLink
                data-testid="tabmenulist-mobile__option"
                key={`item-${ix}`}
                theme={theme}
                onClick={onCloseSidebar}
                href={it.path}
                target="_self"
              >
                {it.label}
              </MobileExternalLink>
            ) : (
              <MobileLink
                data-testid="tabmenulist-mobile__option"
                key={`item-${ix}`}
                theme={theme}
                active={isActivePath(it.path)}
                onClick={onCloseSidebar}
                to={it.path}
              >
                {it.label}
              </MobileLink>
            ),
          )}
      </DrawerContent>
    </StyledDrawer>
  ) : (
    <DesktopMenuWrapper data-testid="tabmenulist-desktop">
      {isActive('darkTheme') && (
        <TabItem onClick={themeColorModeContext.toggle}>
          {themeColorModeContext.mode === ThemeMode.DARK ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </TabItem>
      )}

      {menus.map((it, ix) => {
        if (!it.items) {
          return (
            <StyledLink
              data-testid="tabmenulist-desktop__option"
              key={`item-${ix}`}
              theme={theme}
              ref={it.ref}
              active="false"
              to="#"
              onClick={() => {
                window.location.href = it.path;
              }}
            >
              {it.label}
            </StyledLink>
          )
        }

        const toogleMenuFn = (value: boolean) => {
          setMenusOpened(opened => opened.map((v, i) => i === ix ? value : v));
        };

        return (
          <>
            <StyledLink
              data-testid="tabmenulist-desktop__option"
              key={`item-${ix}`}
              theme={theme}
              ref={it.ref}
              active={'false'}
              to={'#'}
              onClick={() => handleToggle(menusOpened[ix], toogleMenuFn)}
            >
              {it.label}
            </StyledLink>
            <Popper
              data-testid="tabmenulist-desktop__dropdown"
              open={!!menusOpened[ix]}
              anchorEl={it.ref?.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
              style={{ zIndex: 2 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => handleClose(toogleMenuFn)}
                    >
                      <MenuList
                        autoFocusItem={!!menusOpened[ix]}
                        style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
                      >
                        {(it.items || []).map((sub, subIx) =>
                          sub.external ? (
                            <StyledSubExternalLink
                              data-testid="tabmenulist-desktop__dropdown__menu__option"
                              key={`item-link-${subIx}`}
                              href={sub.path}
                              target="_self"
                            >
                              <StyledMenuItem
                                onClick={() => handleClose(toogleMenuFn)}
                              >
                                {sub.label}
                              </StyledMenuItem>
                            </StyledSubExternalLink>
                          ) : (
                            <StyledSubLink
                              data-testid="tabmenulist-desktop__dropdown__menu__option"
                              key={`item-link-${subIx}`}
                              theme={theme}
                              active={isActivePath(sub.path)}
                              to={sub.path}
                            >
                              <StyledMenuItem
                                onClick={() => handleClose(toogleMenuFn)}
                              >
                                {sub.label}
                              </StyledMenuItem>
                            </StyledSubLink>
                          ),
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        );
      })}
    </DesktopMenuWrapper>
  );
}
