const MAINNET_ALGORAND_OPUL_ASSET_ID = 287867876;
const MAINNET_ALGORAND_USDC_ASSET_ID = 31566704;

const envVarInt = (
  s: string | undefined,
  defaultVal?: number,
): number | undefined =>
  /^\d+$/g.test(s as string) ? parseInt(s as string, 10) : defaultVal;

const envVarBoolean = (value: string | undefined): boolean => {
  return value === 'true';
};

export default {
  env: {
    API_URL: process.env.REACT_APP_API_URL ?? '',
    FEATURE_FLAGS: process.env.REACT_APP_FEATURE_FLAGS,
    LATEST_POOL_ID: envVarInt(process.env.REACT_APP_LATEST_POOL_ID) as number,
    ALGO_EXPLORER_URL: process.env.REACT_APP_ALGO_EXPLORER_URL,
    ALGORAND_OPUL_ASSET_ID: envVarInt(
      process.env.REACT_APP_ALGORAND_OPUL_ASSET_ID,
      MAINNET_ALGORAND_OPUL_ASSET_ID,
    ) as number,
    ALGORAND_USDC_ASSET_ID: envVarInt(
      process.env.REACT_APP_ALGORAND_USDC_ASSET_ID,
      MAINNET_ALGORAND_USDC_ASSET_ID,
    ) as number,
    INDACOIN_PARTNER_ID: envVarInt(
      process.env.REACT_APP_INDACOIN_PARTNER_ID,
      109,
    ) as number,
    CRM_SUBSCRIBE_ADDRESS: process.env.REACT_APP_CRM_SUBSCRIBE_ADDRESS ?? '',
    WEB_SITE_ADDRESS: process.env.REACT_APP_WEB_SITE_ADDRESS ?? '',
    MFT_MAIL_TO_ADDRESS: process.env.REACT_APP_MFT_MAIL_TO_ADDRESS ?? '',
    SIGNER_APP_CONNECT_PORT: envVarInt(
      process.env.REACT_APP_SIGNER_APP_CONNECT_PORT,
      3123,
    ) as number,
    WALLET_MY_ALGO_ACTIVE: envVarBoolean(
      process.env.REACT_APP_WALLET_MY_ALGO_ACTIVE,
    ),
    ULTRADE_API_URL: process.env.REACT_APP_ULTRADE_API_URL ?? '',
    ROCKETFUEL_HOSTED_PAGE_URL:
      process.env.REACT_APP_ROCKETFUEL_HOSTED_PAGE_URL ?? '',
    MENU_AI_URL: process.env.REACT_APP_MENU_AI_URL ?? '',
    MENU_FINANCE_OLOAN_URL: process.env.REACT_APP_MENU_FINANCE_OLOAN_URL ?? '',
    MENU_FINANCE_OVAULT_URL:
      process.env.REACT_APP_MENU_FINANCE_OVAULT_URL ?? '',
    MENU_OPUL_TOKEN_BUY_URL:
      process.env.REACT_APP_MENU_OPUL_TOKEN_BUY_URL ?? '',
    MENU_OPUL_TOKEN_ABOUT_URL:
      process.env.REACT_APP_MENU_OPUL_TOKEN_ABOUT_URL ?? '',
    MENU_RESOURCES_BLOG_URL:
      process.env.REACT_APP_MENU_RESOURCES_BLOG_URL ?? '',
    MENU_RESOURCES_HELP_CENTER_URL:
      process.env.REACT_APP_MENU_RESOURCES_HELP_CENTER_URL ?? '',
    MENU_RESOURCES_ABOUT_OPULOUS_URL:
      process.env.REACT_APP_MENU_RESOURCES_ABOUT_OPULOUS_URL ?? '',
    MENU_RESOURCES_RELEASE_MFT_URL:
      process.env.REACT_APP_MENU_RESOURCES_RELEASE_MFT_URL ?? '',
    MENU_COMMUNITY_AMBASSADORS_URL:
      process.env.REACT_APP_MENU_COMMUNITY_AMBASSADORS_URL ?? '',
    MENU_COMMUNITY_TWITTER_URL:
      process.env.REACT_APP_MENU_COMMUNITY_TWITTER_URL ?? '',
    MENU_COMMUNITY_INSTAGRAM_URL:
      process.env.REACT_APP_MENU_COMMUNITY_INSTAGRAM_URL ?? '',
    MENU_COMMUNITY_LINKEDIN_URL:
      process.env.REACT_APP_MENU_COMMUNITY_LINKEDIN_URL ?? '',
    MENU_COMMUNITY_TELEGRAM_URL:
      process.env.REACT_APP_MENU_COMMUNITY_TELEGRAM_URL ?? '',
    MENU_COMMUNITY_DISCORD_URL:
      process.env.REACT_APP_MENU_COMMUNITY_DISCORD_URL ?? '',
    MENU_COMMUNITY_YOUTUBE_URL:
      process.env.REACT_APP_MENU_COMMUNITY_YOUTUBE_URL ?? '',
  },
};
