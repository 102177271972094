import AdminNotFoundPage from '../pages/admin/NotFound';
import Dashboard from '@opulous/web/src/pages/admin/Dashboard';
import AdminLogin from '@opulous/web/src/pages/admin/AdminLogin';
import RafflePage from '@opulous/web/src/pages/admin/RafflePage';
import PoolPage from '@opulous/web/src/pages/admin/PoolPage';
import AssetPage from '@opulous/web/src/pages/admin/AssetPage';
import AssetCreate from '@opulous/web/src/pages/admin/AssetCreate';
import AssetEdit from '@opulous/web/src/pages/admin/AssetEdit';
import RaffleCreate from '@opulous/web/src/pages/admin/RaffleCreate';
import RaffleEdit from '@opulous/web/src/pages/admin/RaffleEdit';
import {
  Navigate,
  NavigateProps,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Feature from '@opulous/web/src/components/Feature';
import ClaimsPage from '@opulous/web/src/pages/Claims';
import LoginPage from '@opulous/web/src/pages/Login';
import AssetsPage from '@opulous/web/src/pages/Assets/';
import RafflesPage from '@opulous/web/src/pages/Raffles';
import AssetDetail from '@opulous/web/src/pages/Assets/AssetDetail';
import PoolsPage from '@opulous/web/src/pages/Investor/Pools';
import PoolDetail from '@opulous/web/src/pages/Investor/Pools/PoolDetail';
import NotFoundPage from '@opulous/web/src/pages/NotFound';
import { RequireWallet } from '@opulous/web/src/routes/utils';
import AnalyticsPage from '@opulous/web/src/pages/Analytics';
import RoyaltiesPage from '@opulous/web/src/pages/Royalties';
import VestingPage from '@opulous/web/src/pages/Vesting';
import VerifyPage from '@opulous/web/src/pages/Verify';
import OptInMFT from '@opulous/web/src/pages/OptIn';
import Terms from '@opulous/web/src/pages/Terms';
import SalesPage from '@opulous/web/src/pages/Sales';
import InvestmentPage from '@opulous/web/src/pages/Sales/Investment';
import PoolCreate from 'src/pages/admin/PoolCreate';
import PoolEdit from 'src/pages/admin/PoolEdit';
import SalePage from 'src/pages/admin/SalePage';
import SaleEdit from 'src/pages/admin/SaleEdit';
import SaleCreate from 'src/pages/admin/SaleCreate';
import ExchangePage from '@opulous/web/src/pages/Exchange';
import IssuancePage from 'src/pages/admin/IssuancePage';
import RewardsPage from 'src/pages/admin/RewardsPage';

function NavigateWithCtx(
  props: Omit<NavigateProps, 'to'> & {
    to: (ctx: { params: any; searchParams: URLSearchParams }) => string;
  },
) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  return (
    <Navigate
      {...props}
      to={props.to({
        params,
        searchParams,
      })}
    />
  );
}

const routes = [
  {
    path: '/',
    element: <Navigate to="/mfts" replace />,
  },
  {
    path: '/assets',
    element: (
      <RequireWallet>
        <AssetsPage />
      </RequireWallet>
    ),
  },
  {
    path: '/tokens/:assetId',
    element: <AssetDetail />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/claim',
    element: (
      <RequireWallet>
        <ClaimsPage />
      </RequireWallet>
    ),
  },
  {
    path: '/pools',
    element: <PoolsPage />,
  },
  {
    path: '/pools/:poolId',
    element: <PoolDetail />,
  },
  {
    path: '/rewards',
    element: (
      <Feature flagName="rewards" redirectTo="/not-found">
        <RequireWallet>
          <RoyaltiesPage />
        </RequireWallet>
      </Feature>
    ),
  },
  {
    path: '/analytics',
    element: (
      <Feature flagName="analytics" redirectTo="/not-found">
        <AnalyticsPage />
      </Feature>
    ),
  },
  {
    path: '/vesting',
    element: (
      <RequireWallet>
        <VestingPage />
      </RequireWallet>
    ),
  },
  {
    path: '/raffles',
    element: <RafflesPage />,
  },
  {
    path: '/admin/not-found',
    element: <AdminNotFoundPage />,
  },
  {
    path: '/exchange',
    element: (
      <Feature flagName="exchange" redirectTo="/not-found">
        <ExchangePage />
      </Feature>
    ),
  },
  {
    path: '/not-found',
    element: <NotFoundPage />,
  },
  {
    path: '/verify',
    element: <VerifyPage />,
  },
  {
    path: '/optin/PUMP1',
    element: <OptInMFT unitName="MONAW" />,
  },
  {
    path: '/optin/ADZ1',
    element: <OptInMFT unitName="ADZ1" />,
  },
  {
    path: '/optin/KYLE1',
    element: <OptInMFT unitName="KYLE1" />,
  },
  {
    path: '/optin/KYLE1',
    element: <OptInMFT unitName="KYLE1" />,
  },
  {
    path: '/terms',
    element: <Terms />,
  },
  {
    path: '/admin',
    // NOTE: Alias to dashboard
    element: <Navigate to="/admin/dashboard" />,
  },
  {
    path: '/admin/login',
    element: <AdminLogin />,
  },
  {
    path: '/admin/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/sales',
    element: <Navigate to="/mfts" replace />,
  },
  {
    path: '/mfts',
    element: <SalesPage />,
  },
  {
    path: '/sales/:investmentId',
    element: (
      <NavigateWithCtx
        replace
        to={({ params }) =>
          `/mfts/${encodeURIComponent(params['investmentId'])}`
        }
      />
    ),
  },
  {
    path: '/mfts/:investmentId',
    element: <InvestmentPage />,
  },
  {
    path: '/admin/raffles',
    element: <RafflePage />,
  },
  {
    path: '/admin/raffles/create',
    element: <RaffleCreate />,
  },
  {
    path: '/admin/raffles/:id/edit',
    element: <RaffleEdit />,
  },
  {
    path: '/admin/pools',
    element: <PoolPage />,
  },
  {
    path: '/admin/pools/create',
    element: <PoolCreate />,
  },
  {
    path: '/admin/pools/:id/edit',
    element: <PoolEdit />,
  },
  {
    path: '/admin/assets',
    element: <AssetPage />,
  },
  {
    path: '/admin/assets/create',
    element: <AssetCreate />,
  },
  {
    path: '/admin/assets/:unitName/edit',
    element: <AssetEdit />,
  },
  {
    path: '/admin/issuances',
    element: <IssuancePage />,
  },
  {
    path: '/admin/rewards',
    element: <RewardsPage />,
  },
  {
    path: '/admin/sales',
    element: <SalePage />,
  },
  {
    path: '/admin/sales/create',
    element: <SaleCreate />,
  },
  {
    path: '/admin/sales/:id/edit',
    element: <SaleEdit />,
  },
  {
    path: '/admin/*',
    element: <Navigate to="/admin/not-found" />,
  },
  {
    path: '*',
    element: <Navigate to="/not-found" />,
  },
];

export default routes;
