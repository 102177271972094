import BigNumber from 'bignumber.js';

export default class MinorOPUL extends BigNumber {
  static ZERO = new this('0');
  static MAX_SAFE_MINOR_OPUL_AMOUNT = MinorOPUL.create(BigInt('18446744073709551615'));
  private static MINOR_DECIMAL = 10;

  private constructor(amount: string | BigNumber) {
    super(amount);
    if (super.isNaN()) {
      throw Error('A minor opul value must be a big int valid.');
    }
  }

  toBigInt(): bigint {
    return BigInt(this.toString());
  }

  static fromOPUL(amountOpul: number): MinorOPUL {
    const parts = amountOpul.toString().split('.');
    if (parts.length > 1 && parts[1].length > MinorOPUL.MINOR_DECIMAL) {
      throw Error(`An opul value must have a maximum of ${MinorOPUL.MINOR_DECIMAL} decimal places.`);
    }
    return new this(
      new BigNumber(amountOpul).multipliedBy(
        Math.pow(10, MinorOPUL.MINOR_DECIMAL),
      ),
    );
  }

  static create(amount: bigint | number | string | BigNumber): MinorOPUL {
    if (amount.toString().split('.').length > 1) {
      throw Error(`A minor opul value must have an integer or bigint.`);
    }
    return new this(amount.toString());
  }

  plus(amount: number | string | BigNumber): MinorOPUL {
    return new MinorOPUL(super.plus(amount));
  }

  minus(amount: number | string | BigNumber): MinorOPUL {
    return new MinorOPUL(super.minus(amount));
  }

  div(amount: number | string | BigNumber): MinorOPUL {
    return new MinorOPUL(super.div(amount));
  }

  dividedBy(amount: number | string | BigNumber): MinorOPUL {
    return new MinorOPUL(super.dividedBy(amount));
  }

  times(amount: number | string | BigNumber): MinorOPUL {
    return new MinorOPUL(super.times(amount));
  }

  multipliedBy(amount: number | string | BigNumber): MinorOPUL {
    return new MinorOPUL(super.multipliedBy(amount));
  }
}
